import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { Row, Shim, PageTitle } from "../components/row"
import SEO from "../components/seo"

const pageTitle = "Cake Not Found"

export default () => (
  <Layout>
    <SEO title={pageTitle} />
    <Row>
      <PageTitle>{pageTitle}</PageTitle>
      <Shim
        style={{ fontSize: "28px", textAlign: "center", fontWeight: "200" }}
      >
        <p>
          <strong>Uh-oh!</strong> The page or image you were looking for wasn't
          able to be found. Use this{" "}
          <Link to="/">link to go back to the homepage</Link>.
        </p>
      </Shim>
    </Row>
  </Layout>
)
